<template>
  <div style="padding: 20px;">
    <portal v-if="loaded" to="view-title">
      <nuxt-link :to="`/documentation`">
        <i class="mdi mdi-book" />
        Documentation
      </nuxt-link>
      <i class="mdi mdi-menu-right" /> Référence
      <i class="mdi mdi-menu-right" /> {{currentItem.type}}
      <i class="mdi mdi-menu-right" />{{currentItem.name}}
    </portal>
    <div v-if="currentItem.frontSpec">
      <i :class="`mdi mdi-${currentItem.frontSpec.icon} mdi-48px`" />
      {{currentItem.frontSpec.description}}
      <h4 v-if="currentItem.frontSpec.specificOptions">
        Options spécifiques
      </h4>
      <ul v-if="currentItem.frontSpec.specificOptions">
        <li v-for="o in currentItem.frontSpec.specificOptions" :key="o.model">
          <span class="tag">{{o.type}}</span>
          {{o.label}}
          ({{o.model}})
        </li>
      </ul>
    </div>
    <div v-if="issues">
      <h3>Issues</h3>
      <div v-for="i in issues" :key="i._id" class="card">
        <div class="card-content" style="padding: 10px;">
          <span class="tag">{{i.criticite}}</span>
          <span class="tag"> <i class="mdi mdi-date" />
            {{new Date(i._metadatas.creationDate).toLocaleDateString()}}
          </span>
          {{i.description}}
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { mapState } from 'vuex';
import VRuntimeTemplate from 'v-runtime-template';

export default {
  name: 'DocumentationReferencePage',
  components: {
    VRuntimeTemplate,
  },
  mounted() {
    this.$store.dispatch('documentation/load');
  },
  data() {
    return {
      issues: undefined,
    };
  },
  watch: {
    currentItem: {
      async handler(v) {
        if (v) {
          const res = await this.$axios.get(`https://server.gwenp.fr:3333/dashy-bugs?query={"referentiel":"${v.name}"}`, {
            headers: { authorization: `apiKey ${process.env.VUE_APP_REFERENTIEL_API}` },
          });
          console.log('issues', res);
          this.issues = res.data.documents;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      referenceItems: (state) => state.documentation.referenceItems,
      loaded: (state) => state.documentation.loaded,
    }),
    currentItem() {
      if (this.loaded) {
        return this.referenceItems.filter((i) => i._id === this.$route.params.id)[0];
      }
      return undefined;
    },
  },
};
</script>
<style scoped>
</style>
